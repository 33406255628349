<template>
  <div class="add-your-bank-name-main-wrapper">
    <a-row style="display: unset">
      <a-col>
        <div style="margin-top: 100px">
          <!-- <div>
            <img src='../../../assets/panImage.png' />
          </div> -->
          <div>
            <div class="add-your-bank-name-title">Add Your Bank</div>

            <div class="search-and-add-your-bank-fields-wrapper">
              <a-select
                show-search
                v-model:value="document"
                :size="size"
                style="width: 100%; text-align: initial"
                class="select-field"
                :options="annualIncomeOptions"
                placeholder="Search for your bank"
              ></a-select>
            </div>
            <div
              class="bank-list-wrapper"
              style="
                text-align: initial;
                display: flex;
                width: 50%;
                margin-right: 10px;
                margin-top: 20px;
              "
            >
              <div
                class="bank-list"
                style="margin-right: 10px"
                @click="bankNameClick"
              >
                Bank Name
              </div>
              <!-- <div class="bank-list">Bank Name</div> -->
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
// import { ArrowRightOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    // ArrowRightOutlined,
  },
  setup() {
    onMounted(() => {
      window.scrollTo(0, 0);
    });
    const router = useRouter();
    const bankNameClick = () => {
      router.push('/onboarding/bank/details');
    };

    return {
      bankNameClick,
      value1: ref(),
      checked: ref(false),
    };
  },
};
</script>

<style lang='scss'>
@import "../styles/addYourBankName.scss";
</style>
